<template>
  <div class="navbar">
    <nav>
      <h1 @click="goHome">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 660 70.091"
          enable-background="new 0 0 660 70.091"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                fill="#001E41"
                d="M53.364,5.301L31.231,65.617h-8.834L0.263,5.301h8.163L26.855,57.31L45.284,5.301H53.364z"
              />
              <path
                fill="#001E41"
                d="M31.929,66.617h-10.23L-1.169,4.301H9.133l17.722,50.015L44.577,4.301h10.221L31.929,66.617z
			 M23.095,64.617h7.438L51.932,6.301h-5.941L26.855,60.304L7.719,6.301H1.696L23.095,64.617z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M172.352,22.781c-1.623-3.518-3.986-6.243-7.067-8.18c-3.089-1.93-6.677-2.898-10.772-2.898
			c-4.095,0-7.78,0.968-11.067,2.898c-3.28,1.937-5.862,4.704-7.738,8.31c-1.883,3.606-2.821,7.775-2.821,12.504
			c0,4.733,0.938,8.884,2.821,12.462c1.876,3.578,4.458,6.331,7.738,8.264c3.287,1.933,6.971,2.898,11.067,2.898
			c5.725,0,10.437-1.757,14.142-5.278c3.698-3.518,5.862-8.279,6.478-14.279h-23.394v-6.405h31.557v6.06
			c-0.452,4.961-1.965,9.504-4.547,13.627c-2.582,4.127-5.972,7.388-10.183,9.782c-4.205,2.394-8.889,3.588-14.053,3.588
			c-5.444,0-10.402-1.31-14.895-3.937c-4.486-2.623-8.033-6.275-10.642-10.948c-2.609-4.673-3.917-9.951-3.917-15.835
			s1.308-11.177,3.917-15.881c2.609-4.701,6.157-8.363,10.642-10.99c4.492-2.623,9.451-3.937,14.895-3.937
			c6.225,0,11.738,1.588,16.538,4.761c4.794,3.173,8.286,7.645,10.478,13.413H172.352z"
              />
              <path
                fill="#001E41"
                d="M154.512,67.134c-5.596,0-10.776-1.371-15.399-4.074c-4.619-2.701-8.323-6.511-11.011-11.323
			c-2.684-4.808-4.044-10.299-4.044-16.323c0-6.021,1.36-11.528,4.042-16.366c2.685-4.836,6.39-8.661,11.012-11.368
			c4.63-2.704,9.811-4.074,15.4-4.074c6.394,0,12.144,1.658,17.091,4.926c4.949,3.277,8.604,7.95,10.86,13.892l0.515,1.355h-11.266
			l-0.269-0.581c-1.529-3.315-3.78-5.923-6.691-7.752c-2.914-1.821-6.36-2.745-10.24-2.745c-3.887,0-7.44,0.929-10.561,2.76
			c-3.104,1.833-5.58,4.495-7.357,7.909c-1.797,3.441-2.708,7.492-2.708,12.042c0,4.55,0.91,8.586,2.706,11.996
			c1.778,3.391,4.255,6.038,7.361,7.869c3.113,1.831,6.666,2.76,10.559,2.76c5.434,0,9.959-1.684,13.453-5.003
			c3.279-3.12,5.309-7.339,6.039-12.554h-23.266v-8.405h33.557v7.06c-0.469,5.192-2.049,9.925-4.699,14.158
			c-2.661,4.253-6.206,7.658-10.537,10.12C164.726,65.883,159.832,67.134,154.512,67.134z M154.512,5.607
			c-5.23,0-10.072,1.279-14.391,3.8c-4.308,2.523-7.765,6.094-10.272,10.612c-2.516,4.537-3.791,9.717-3.791,15.396
			c0,5.679,1.275,10.843,3.79,15.348c2.511,4.496,5.968,8.053,10.274,10.571c4.312,2.521,9.153,3.8,14.39,3.8
			c4.968,0,9.529-1.163,13.558-3.458c4.04-2.296,7.348-5.474,9.831-9.443c2.482-3.965,3.963-8.402,4.398-13.188l-0.003-4.969
			h-29.558v4.405h23.502l-0.113,1.102c-0.639,6.213-2.921,11.226-6.784,14.901c-3.878,3.686-8.868,5.554-14.831,5.554
			c-4.254,0-8.148-1.021-11.573-3.037c-3.434-2.023-6.164-4.938-8.117-8.662c-1.947-3.699-2.936-8.048-2.936-12.926
			c0-4.876,0.987-9.239,2.935-12.967c1.951-3.75,4.683-6.681,8.116-8.708c3.434-2.015,7.327-3.037,11.575-3.037
			c4.26,0,8.062,1.026,11.303,3.05c3.073,1.931,5.481,4.629,7.167,8.028h7.062c-2.127-4.9-5.333-8.791-9.544-11.579
			C165.886,7.153,160.506,5.607,154.512,5.607z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M201.046,11.703v20.163h21.38v6.493h-21.38v20.768h23.9v6.49H193.39V5.213h31.557v6.49H201.046z"
              />
              <path
                fill="#001E41"
                d="M225.947,66.617H192.39V4.213h33.557v8.49h-23.9v18.163h21.38v8.493h-21.38v18.769h23.9V66.617z
			 M194.39,64.617h29.557v-4.49h-23.9V37.359h21.38v-4.493h-21.38V10.703h23.9v-4.49H194.39V64.617z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M293.703,52.204h-25.585l-4.712,13.413h-8.074l21.202-59.971h8.834l21.127,59.971h-8.081L293.703,52.204z
			 M291.511,45.799L280.91,15.337l-10.601,30.462H291.511z"
              />
              <path
                fill="#001E41"
                d="M307.907,66.617h-10.201l-4.712-13.413h-24.167l-4.712,13.413h-10.197l21.909-61.971h10.25L307.907,66.617
			z M299.124,64.617h5.959L284.661,6.646h-7.42l-20.495,57.971h5.951l4.712-13.413h27.003L299.124,64.617z M292.919,46.799h-24.017
			l12.008-34.505L292.919,46.799z M271.716,44.799h18.388l-9.194-26.42L271.716,44.799z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M316.761,19.534c2.575-4.701,6.102-8.377,10.56-11.032s9.409-3.983,14.854-3.983
			c6.389,0,11.978,1.588,16.744,4.761c4.766,3.173,8.245,7.673,10.437,13.501h-9.177c-1.623-3.634-3.965-6.433-7.026-8.395
			c-3.054-1.961-6.718-2.944-10.978-2.944c-4.095,0-7.773,0.982-11.026,2.944s-5.807,4.747-7.656,8.352
			c-1.856,3.606-2.78,7.831-2.78,12.677c0,4.789,0.924,8.986,2.78,12.592c1.849,3.606,4.403,6.391,7.656,8.349
			c3.253,1.965,6.93,2.944,11.026,2.944c4.26,0,7.923-0.965,10.978-2.898c3.061-1.933,5.403-4.719,7.026-8.352h9.177
			c-2.191,5.771-5.67,10.226-10.437,13.37c-4.766,3.144-10.354,4.715-16.744,4.715c-5.444,0-10.396-1.31-14.854-3.937
			c-4.458-2.623-7.985-6.275-10.56-10.948c-2.582-4.673-3.876-9.951-3.876-15.835S314.179,24.238,316.761,19.534z"
              />
              <path
                fill="#001E41"
                d="M342.174,67.134c-5.604,0-10.771-1.371-15.361-4.076c-4.602-2.708-8.278-6.518-10.929-11.326
			c-2.654-4.806-4-10.296-4-16.318c0-6.02,1.346-11.525,3.999-16.362c2.646-4.829,6.322-8.668,10.926-11.411
			c4.595-2.736,9.764-4.123,15.365-4.123c6.565,0,12.386,1.658,17.298,4.928c4.923,3.277,8.562,7.981,10.818,13.981l0.509,1.352
			h-11.27l-0.265-0.592c-1.535-3.438-3.773-6.116-6.652-7.96c-2.879-1.849-6.391-2.786-10.438-2.786
			c-3.892,0-7.428,0.942-10.509,2.8c-3.079,1.856-5.529,4.532-7.283,7.952c-1.771,3.442-2.67,7.553-2.67,12.22
			c0,4.611,0.898,8.694,2.669,12.135c1.756,3.423,4.206,6.098,7.283,7.949c3.077,1.859,6.612,2.801,10.51,2.801
			c4.054,0,7.567-0.923,10.442-2.743c2.878-1.817,5.114-4.48,6.648-7.916l0.265-0.592h11.274l-0.515,1.355
			c-2.256,5.941-5.896,10.601-10.82,13.85C354.56,65.492,348.741,67.134,342.174,67.134z M342.174,5.519
			c-5.236,0-10.062,1.292-14.342,3.842c-4.293,2.557-7.724,6.141-10.195,10.653v0c-2.49,4.539-3.753,9.72-3.753,15.4
			c0,5.68,1.262,10.845,3.751,15.352c2.475,4.49,5.904,8.045,10.192,10.569c4.278,2.521,9.104,3.799,14.347,3.799
			c6.168,0,11.616-1.531,16.193-4.55c4.188-2.763,7.379-6.639,9.502-11.536h-7.053c-1.688,3.518-4.084,6.274-7.131,8.198
			c-3.199,2.025-7.073,3.052-11.512,3.052c-4.267,0-8.15-1.039-11.542-3.088c-3.4-2.046-6.103-4.99-8.029-8.749
			c-1.918-3.725-2.891-8.115-2.891-13.048c0-4.989,0.973-9.408,2.892-13.134c1.925-3.754,4.626-6.699,8.028-8.751
			c3.397-2.049,7.281-3.087,11.542-3.087c4.438,0,8.312,1.044,11.518,3.103c3.044,1.95,5.438,4.719,7.125,8.236h7.059
			c-2.124-4.956-5.319-8.877-9.511-11.668C353.786,7.065,348.339,5.519,342.174,5.519z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M415.382,52.204h-25.585l-4.712,13.413h-8.074l21.202-59.971h8.834l21.127,59.971h-8.081L415.382,52.204z
			 M413.19,45.799l-10.601-30.462l-10.601,30.462H413.19z"
              />
              <path
                fill="#001E41"
                d="M429.587,66.617h-10.202l-4.712-13.413h-24.167l-4.711,13.413h-10.197l21.909-61.971h10.249
			L429.587,66.617z M420.802,64.617h5.96L406.34,6.646h-7.419l-20.495,57.971h5.951l4.711-13.413h27.003L420.802,64.617z
			 M414.597,46.799h-24.017l12.009-34.505L414.597,46.799z M393.395,44.799h18.388l-9.193-26.42L393.395,44.799z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M472.311,8.977c4.677,2.454,8.273,5.958,10.765,10.515c2.5,4.557,3.746,9.923,3.746,16.096
			s-1.246,11.525-3.746,16.053c-2.493,4.528-6.088,7.99-10.765,10.384c-4.691,2.394-10.224,3.592-16.621,3.592h-18.264V5.301h18.264
			C462.087,5.301,467.62,6.526,472.311,8.977z M473.023,52.94c3.986-4.127,5.972-9.909,5.972-17.353
			c0-7.5-2.007-13.356-6.013-17.568c-4.013-4.211-9.779-6.317-17.292-6.317h-10.608v47.424h10.608
			C463.265,59.127,469.038,57.064,473.023,52.94z"
              />
              <path
                fill="#001E41"
                d="M455.69,66.617h-19.264V4.301h19.264c6.522,0,12.27,1.275,17.084,3.79l0.002,0
			c4.843,2.541,8.604,6.215,11.178,10.92c2.567,4.68,3.869,10.257,3.869,16.576c0,6.319-1.303,11.882-3.87,16.537
			c-2.577,4.68-6.341,8.311-11.186,10.791C467.954,65.372,462.209,66.617,455.69,66.617z M438.426,64.617h17.264
			c6.2,0,11.639-1.172,16.166-3.482c4.483-2.294,7.964-5.651,10.345-9.976c2.403-4.355,3.622-9.594,3.622-15.571
			c0-5.979-1.219-11.232-3.623-15.615c-2.382-4.354-5.866-7.756-10.354-10.11c-4.524-2.364-9.961-3.562-16.156-3.562h-17.264V64.617
			z M455.69,60.127h-11.607V10.703h11.607c7.765,0,13.826,2.229,18.016,6.627c4.174,4.386,6.289,10.529,6.289,18.258
			c0,7.68-2.104,13.752-6.252,18.047h-0.001C469.579,57.943,463.506,60.127,455.69,60.127z M446.083,58.127h9.607
			c7.252,0,12.842-1.979,16.613-5.882l0,0c3.776-3.909,5.691-9.514,5.691-16.658c0-7.199-1.931-12.877-5.737-16.878
			c-3.797-3.985-9.371-6.006-16.567-6.006h-9.607V58.127z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M504.573,11.703v20.163h21.38v6.493h-21.38v20.768h23.9v6.49h-31.557V5.213h31.557v6.49H504.573z"
              />
              <path
                fill="#001E41"
                d="M529.473,66.617h-33.557V4.213h33.557v8.49h-23.9v18.163h21.381v8.493h-21.381v18.769h23.9V66.617z
			 M497.917,64.617h29.557v-4.49h-23.9V37.359h21.381v-4.493h-21.381V10.703h23.9v-4.49h-29.557V64.617z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M599.585,5.731v59.886h-7.656V20.964l-19.36,44.653h-5.383l-19.442-44.741v44.741h-7.656V5.731h8.245
			l21.544,49.502l21.544-49.502H599.585z"
              />
              <path
                fill="#001E41"
                d="M600.586,66.617h-9.656V25.784l-17.704,40.833h-6.695l-17.785-40.931v40.931h-9.657V4.731h9.901
			l20.889,47.996l20.89-47.996h9.818V66.617z M592.929,64.617h5.656V6.731h-6.508l-22.2,51.008L547.678,6.731h-6.591v57.886h5.657
			V16.065l21.098,48.552h4.07l21.017-48.474V64.617z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M653.611,5.301l-19.107,37.558v22.758h-7.656V42.859L607.659,5.301h8.505l14.47,30.547l14.477-30.547
			H653.611z"
              />
              <path
                fill="#001E41"
                d="M635.505,66.617h-9.656V43.099L606.026,4.301h10.771l13.838,29.211l13.844-29.211h10.763l-19.737,38.797
			V66.617z M627.848,64.617h5.656V42.619l0.108-0.214L651.98,6.301h-6.235l-15.109,31.882L615.532,6.301h-6.238l18.555,36.317
			V64.617z"
              />
            </g>
          </g>
          <rect x="-31.389" y="-1.691" fill="none" width="691" height="93" />
          <g>
            <g>
              <path
                fill="#001E41"
                d="M105.801,12.446C94.839,1.652,77.204,1.702,66.306,12.6s-10.948,28.533-0.154,39.495L105.801,12.446z"
              />
            </g>
            <g>
              <path
                fill="#001E41"
                d="M65.389,59.655c10.962,10.794,28.597,10.743,39.495-0.154s10.948-28.533,0.154-39.495L65.389,59.655z"
              />
            </g>
          </g>
        </svg>
      </h1>
      <div class="links">
        <div v-if="displayName">
          <!-- <span> Hi there, {{ displayName }}</span> -->

          <input
            type="checkbox"
            class="openSidebarMenu"
            id="openSidebarMenu"
            v-model="isChecked"
          />
          <label for="openSidebarMenu" class="sidebarIconToggle">
            <div class="spinner diagonal part-1"></div>
            <div class="spinner horizontal"></div>
            <div class="spinner diagonal part-2"></div>
          </label>

          <div id="sidebarMenu">
            <ul class="sidebarMenuInner">
              <li v-if="courses.length==0">
                <router-link :to="{ name: 'home' }"
                    >Home</router-link
                  >
              </li>
              <li v-if="courses.length>0">
                <span @click="goToCourse">Home</span>
              </li>
              <li v-if="isAdmin">
                <span @click="unChecked"
                  ><router-link :to="{ name: 'VideoAdmin' }"
                    >Video Admin</router-link
                  ></span
                >
              </li>
              <li v-if="isAdmin">
                <span @click="unChecked">
                  <router-link :to="{ name: 'ToolkitAdmin' }"
                    >Toolkit Admin</router-link
                  >
                </span>
              </li>
              <li v-if="courses.length>0">
                <span @click="unChecked"
                  ><router-link :to="{ name: 'MyToolkit' }"
                    >My Toolkit</router-link
                  ></span
                >
              </li>
              <li>
                <span @click="unChecked"
                  ><router-link :to="{ name: 'MemAccount' }"
                    >Account</router-link
                  ></span
                >
              </li>
              <li><span class="sb-item" @click="handleClick">Logout</span></li>
            </ul>
          </div>
        </div>
        <div v-else class="space-buttons">
          <router-link class="log-button" :to="{ name: 'Signup' }">Create Account</router-link>
          <router-link class="log-button" :to="{ name: 'Login' }">Login</router-link>
        </div>
      </div>
    </nav>
  </div>
  <!-- <div v-if="ModuleShow">
          <ModalMenu @modalClose="modalClose"/>
        </div> -->
</template>

<script>
import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser";
import { ref, onMounted, watchEffect } from "vue";
import { userStore } from "@/store/userStore";
import { coursesStore } from "@/store/coursesStore";
import ModalMenu from "./ModalMenu.vue";

export default {
  components: { ModalMenu },
  setup(props, context) {
    const store = userStore();
    const cstore = coursesStore();
    const { logout, error } = useLogout();
    const router = useRouter();
    const isAdmin = ref();
    const displayName = ref();
    const ModuleShow = ref(false);
    const matty = ref(localStorage.getItem("displayName"));
    const isChecked = ref(false);
    const courses = ref([])
    watchEffect(() =>{
      courses.value = store.userCourses
    })


    store.$subscribe((login, state) => {
      displayName.value = store.displayName;
      isAdmin.value = store.admin;
      courses.value = store.userCourses
    });

    onMounted(() => {
      displayName.value = store.displayName;
      isAdmin.value = store.admin;
      courses.value = store.userCourses
    });

    const showModule = () => {
      ModuleShow.value = true;
    };

    const modalClose = () => {
      ModuleShow.value = false;
    };

    const handleClick = () => {
      // store.setTechniques();
      store.$reset();
      cstore.$reset();
      logout();
      router.push({ name: "Login" });
      isChecked.value = false;
    };

    const unChecked = () => {
      isChecked.value = false;
    };

    const goToCourse = () => {
      isChecked.value = false;
      router.push({
        name: "CourseView",
        params: { course: "procrastination" },
      });
    };

    const goHome = () =>{
      isChecked.value = false;
      if (courses.value.length>0){
        router.push({
        name: "CourseView",
        params: { course: "procrastination" },
      });
      } else {
      router.push({
        name: "home",
      });
    }
    }

    return {
      handleClick,
      isAdmin,
      displayName,
      showModule,
      ModuleShow,
      modalClose,
      isChecked,
      unChecked,
      goToCourse,
      goHome,
      courses
    };
  },
};
</script>

<style scoped>
.navbar {
  position: fixed;
  width: 100%;
  padding: 16px 10px;
  background: #ffffff;
  border-bottom: 2px solid var(--primeblue);

  z-index: 150;
}

.navbar svg {
  height: 50px;
  width: 400px;
}

nav {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.nav_row {
  display: flex;
}

nav .links {
  margin-left: auto;
}

nav .links button {
  margin-left: 16px;
  font-size: 14px;
}

.space-buttons{
  width:350px;
  display: flex;
  justify-content: space-around;
}

#sidebarMenu {
  height: 100%;
  position: fixed;
  right: 0px;
  width: 160px;
  margin-top: 40px;
  transform: translateX(250px);
  transition: transform 250ms ease-in-out;
  background: var(--primeblue);
}
.sidebarMenuInner {
  margin: 0;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebarMenuInner li {
  list-style: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebarMenuInner li span {
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
}
.sidebarMenuInner li a {
  color: rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.sidebarMenuInner li a:hover,
.sidebarMenuInner li span:hover {
  color: white;
  font-weight: bold;
  
}
input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
}

input[type="checkbox"] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}
.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: fixed;
  z-index: 99;
  height: 100%;
  width: 100%;
  right: 45px;
  height: 30px;
  width: 30px;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #001e41;
}
.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}
.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}

@media screen and (max-width: 600px) {
  nav {
    margin: 0px;
  }

  .navbar svg {
    height: 25px;
    width: 200px;
  }

  .sidebarIconToggle {
    top: 20px;
    right: 20px;
    height: 20px;
    width: 20px;
  }
  #sidebarMenu {
    width: 120px;
    margin-top: 35px;
  }

  .sidebarMenuInner li span {
    font-size: 12px;
  }
}
</style>